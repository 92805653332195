.iphorm-elements .iphorm-element-wrap-text input,
.iphorm-elements .iphorm-element-wrap-textarea textarea, {
	border: none!important;
	background: #EAEAEA!important;
	padding: 0.5rem!important;
	color: black!important;
	min-width: 90%!important;
}

.iphorm-elements .iphorm-submit-wrap .iphorm-submit-element{
	background: $color-accent!important;
	border: 0px!important;
	padding: rem(5px) rem(25px);
	font-weight: 700;
	
	& span{
		background: $color-accent!important;
		border: 0px!important;
	}
	
	& em{
		background: $color-accent!important;
		color: white;
		text-transform: uppercase;
	}
}

.iphorm-required{
	font-size: 1rem!important;
}