// Settings
//------------------------------------------------------------------------------

$row-width: rem(1140px);
$column-gutter: rem(10px);
$total-columns: 12;
$container-margin: rem(20px);

// Default foundation MQs - uncomment & alter if changes are needed:
// $small-range: (0em, 40em);
// $medium-range: (40.063em, 64em);
// $large-range: (64.063em, 90em);
// $xlarge-range: (90.063em, 120em);
// $xxlarge-range: (120.063em, 99999999em);


// Output (Everything below this line can typically be ignored)
//------------------------------------------------------------------------------

$include-html-global-classes: false; // Prevent Foundation outputting its general styles
$include-xl-html-grid-classes: true; // Make xlarge + xxlarge grid classes available
$rem-base: $base-font-size; // Set rem base to that specced in vars

@import 'foundation/foundation/components/grid'; // Output grid

$small-down: "#{$screen} and (max-width:#{upper-bound($small-range)})";
$medium-down: "#{$screen} and (max-width:#{upper-bound($medium-range)})";
$large-down: "#{$screen} and (max-width:#{upper-bound($large-range)})";
$xlarge-down: "#{$screen} and (max-width:#{upper-bound($xlarge-range)})";
$xxlarge-down: "#{$screen} and (max-width:#{upper-bound($xxlarge-range)})";


// For the Coffescript module Breakpoint
$breakpoint-list: ('small');
$breakpoint-map: (
  medium:  #{lower-bound($medium-range)},
  large:   #{lower-bound($large-range)},
  xlarge:  #{lower-bound($xlarge-range)}
);

body:before,
body:after {
  @include visually-hidden;
  content: 'small';
}

@each $breakpoint-name, $breakpoint-value in $breakpoint-map {
  @media only screen and (min-width:#{$breakpoint-value}) {
    body:before {
      content: '#{$breakpoint-name}';
    }
  }
  $breakpoint-list: append($breakpoint-list, $breakpoint-name, space);
}

body:after {
  content: '#{$breakpoint-list}';
}

.container{
	max-width: $row-width + ( $container-margin * 2);
	padding: 0 $container-margin;
	margin: 0 auto;
}