.main-header{
	height: rem($main-header-height);
	background:white;
	z-index: 1;
	position: relative;
	
	@media only screen and (max-width: 1100px) {
		height: $main-header-height-mob;
		margin-top:rem(-$main-header-height-mob);
		width: 100%;
		position: absolute;
		
		& .header-logo i{
			height: rem(86px);
		}
	
	}
	
	& .container{
		position: relative;
		height: rem($main-header-height);
	}
}

.header-logo{
	display: inline-block;
	@media only screen and (max-width: 1100px) {
		text-align: center;
		display: block;
	}
}