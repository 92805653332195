@mixin hardware-acceleration {
  backface-visibility: hidden;
  will-change: transform, opacity;
}

@mixin un-hardware-acceleration {
  backface-visibility: visible;
  will-change: auto;
}

@mixin disable-interaction {
  cursor: default;
  user-select: none;
  pointer-events: none;
}

@mixin enable-interaction {
  cursor: auto;
  user-select: all;
  pointer-events: all;
}

// Apply rules only to hi-res devices=
@mixin retina {
  @media
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 120dpi) {
    @content;
  }
}

// @see http://hugogiraudel.com/2013/08/08/advanced-sass-list-functions/
@function implode($list, $glue: '', $is-nested: false) {
  $result: null;

  @for $i from 1 through length($list) {
    $e: nth($list, $i);

    @if type-of($e) == list {
      $result: $result#{to-string($e, $glue, true)};
    } @else {
      $result: if($i != length($list) or $is-nested, $result#{$e}#{$glue}, $result#{$e});
    }
  }

  @return $result;
}

// Reset styling for third-party embeds (e.g. Google Maps)
@mixin component {
  *,
  *:before,
  *:after {
    box-sizing: content-box;
  }

  img {
    display: inline;
    max-width: none;
  }
}

// Add vendor prefixes (necessary until appearance is supported by autoprefixer)
@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}

//create an rgba from hex
@function hexToRGBA($color, $opacity: 0.3) {
    $red: red($color);
	$green: green($color);
	$blue: blue($color);

	@return rgba($red, $green, $blue, $opacity);
}
