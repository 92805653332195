	.icon--ui__arrow {
		@include rem((width: 20px));

		&:before {
			padding-top: calc((20% / 20%) * 100%);
		}
	}
	.icon--ui__stamco_logo_big {
		@include rem((width: 269px));

		&:before {
			padding-top: calc((174% / 269%) * 100%);
		}
	}
	.icon--ui__stamco_logo_clear {
		@include rem((width: 269px));

		&:before {
			padding-top: calc((174% / 269%) * 100%);
		}
	}
