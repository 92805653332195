.big_button a{
		width: 100%!important;
		height: 150px;
		display: flex!important;
		justify-content: center;
		align-items: center;
		font-size: rem(20px)!important;
		font-weight: 200;
		border:1px solid rgb(221, 51, 51)!important;
}

.vc_btn3.vc_btn3-color-danger, .vc_btn3.vc_btn3-color-danger.vc_btn3-style-flat {
	background-color: $color-accent!important;
	
	&:hover{
		opacity: 0.8;
	}
}