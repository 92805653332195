//@import url('https://fonts.googleapis.com/css?family=Montserrat');

body,
button,
select,
input,
textarea {
  @include font-smoothing(on);
  text-rendering: optimizeLegibility;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  //font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6, 
blockquote, 
dl, dd, 
figure, 
p, pre{
	margin-bottom:1rem;
}