.header-menu{
	display: inline-block;
	position: absolute;
	right: 0;
	bottom: 0;
}

.menu-icon{
	position: absolute;
	top: rem(15px);
	left: rem(15px);
	font-size: rem(25px);
	color: black;
	display: block;
	@media only screen and (min-width: 1100px) {display: none;}
}

.header-menu .menu li{
	@media only screen and (min-width: 1100px) {
		@include animateIt(200ms);
		display: inline-block;
		
		&:last-child{
			margin-roght:0px;
		}
		
		& a{
			@include animateIt(200ms);
			color: black;
			font-weight: 600;
			display: inline-block;
			padding: rem(20px) rem(15px);
			margin-right: rem(5px);
			font-size: 14px;
		}
		
		&:hover{
			background: $color-accent;
			
			& a{
				color: white;
			}
		}
		
		&.current_page_item{
			background: $color-accent;
			
			& a{
				color: white;
				font-weight: 400;
			}
		}
	}
}
@media only screen and (max-width: 1100px) {
	.menu-open{
		//body class die aangeeft dat menu open moet.
		overflow: hidden;
		height: 100vh;
		
		& .wrapper{
			transform: translateX(rem($mobile-menu-width));
		}
		
		& .header-menu{
			transform: translateX(rem(-$mobile-menu-width));
			box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1), 0 3px 10px rgba(0, 0, 0, 0.07);
		}
	}
	
	& .wrapper{
		@include animateIt(200ms);
		transform: translateX(rem(0));
	}
		
	& .header-menu{
		@include animateIt(200ms);
		position: fixed;
		top: rem(-$main-header-height-mob);
		left: 0px;
		width: rem($mobile-menu-width);
		background: white;
		height: 100vh;
		transform: translateX(rem(-$mobile-menu-width));
		border-right: 1px solid RGBA(235, 235, 235, 1.00);
		box-shadow: 0 15px 35px rgba(0, 0, 0, 0.0), 0 3px 10px rgba(0, 0, 0, 0.0);
			
		& .menu li a{
			@include animateIt(200ms);
			border-bottom: 1px solid RGBA(235, 235, 235, 1.00);
			padding: rem(20px) rem(20px);
			display: block;
				
			&:hover{
				background: RGBA(243, 243, 243, 1.00);
			}
		}
	}
}