.vastgoed--featured-image{
	min-height: rem(450px);
	margin-bottom: rem(50px);
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.vastgoed--home{
	padding-bottom: rem(15px);
	padding-right: 0.5rem;
	
	&:last-child{
		padding-right: 0rem;
	}
	
	& .vastgoed--featured-image{
		min-height: rem(200px);
		margin-bottom: rem(10px);
		position: relative;
		
		&__overlay{
			@include animateIt(200ms);
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,0);
			display: block;
			position: absolute;
			
			&:hover{
				background: hexToRGBA($color-accent, 0.5);
				mix-blend-mode:multiply;
			}
		}
	}
	
	&__title{
		margin-bottom: rem(5px);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		
		& a{
			color: black;
		}
		
		& .m-icon{
			margin-bottom: rem(-3px)!important;
			margin-right: rem(5px);
		}
	}
	
	& .vastgoed--bar{
		border: 0px;
		height: rem(3px);
		background: $color-accent;
	}
}

.vastgoed--description__item{
	color: $color-accent; 
	font-weight:600;
	min-width: rem(130px);
	display: inline-block;
}