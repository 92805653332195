html {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  font-size: percentage($base-font-size / 16px);

  //Example of varying site scale at a breakpoint:
  //@media only screen and (min-width: em(1900px)) {
  //	font-size: percentage(($base-font-size + 5px) / 16px);
  //}
  
 // @media only screen and (min-width: em(2500px)) {
 // 	font-size: percentage(($base-font-size + 10px) / 16px);
 //}
}

body {
  min-height: 100%;
  @media only screen and (max-width: 1100px) {padding-top: $main-header-height-mob;}
}

.list--bullet{
	list-style-type: inherit;
	padding-left: 1em;
	
	& li {
		margin: 0.5em 0em 0em 0em;
	}
}

.clearfix:after {
     visibility: hidden;
     display: block;
     font-size: 0;
     content: " ";
     clear: both;
     height: 0;
     }
.clearfix { display: inline-block; }
/* start commented backslash hack \*/
* html .clearfix { height: 1%; }
.clearfix { display: block; }
/* close commented backslash hack */